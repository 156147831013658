import React, { useEffect, useRef } from 'react';
import ScrollTrigger from '../../../../lib/ScrollTrigger';
import { IImage } from '../../../../types/global.types';
import Picture from '../../../picture/picture';

export enum ImageType { Portrait = 'portrait', Square = 'square', FullWidth = 'full-width' }

const Image = ({ image, type, className, index, timeline }: IImage) => {
	const ref = useRef<HTMLDivElement>(null);
	const queries = {
		'portrait': [
			{ alias: 'desktopSmall', height: 769, width: 684, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 820, width: 386, media: '(min-width: 641px)' },
			{ alias: 'mobile', height: 768, width: 361, media: '(max-width: 640px)' },
		],
		'square': [
			{ alias: 'desktop', height: 696, width: 696, media: '(min-width: 768px)' },
			{ alias: 'tablet', height: 346, width: 277, media: '(max-width: 767px)' },
		],
		'full-width': [
			{ alias: 'desktop', height: 769, width: 1366, media: '(min-width: 1024px)' },
			{ alias: 'desktopSmall', height: 576, width: 1024, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 820, width: 1434, media: '(min-width: 641px)' },
			{ alias: 'mobileLarge', height: 768, width: 1344, media: '(max-width: 640px)' },
		],
	};

	useEffect(() => {
		if(!timeline) { return; }

		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			const offset = type !== ImageType.Portrait ? -50 : 25;
			timeline.to(ref.current, { y: offset }, 0);
		}});
	}, [timeline]);
	
	return (
		<section ref={ref} className={`image image--${type} ${className}`}>
			<div className={`image__inner  image__inner--${type} ${index === 0 && type !== ImageType.FullWidth ? '' : ''}`}>
				<Picture image={image} queries={queries[type]}/>
			</div>
		</section>
	);
};

export default Image;