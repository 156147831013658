import React, { useEffect, useMemo, useRef, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from '../../lib/ScrollTrigger';
import { IContentBlock } from '../../types/global.types';
import { richTextResolver } from '../../utils/rich-text-resolver';
import { htmlRaw, timeout } from '../../utils/utils';
import Button from '../button/button';
import CustomLink from '../custom-link/custom-link';

const C1ContentBlock = ({ headline, body,contactFormLink, copyLinkCaption, copiedLinkCaption, alignHeadlineRight }: IContentBlock) => {
	const bodyHtml = useMemo(() => richTextResolver(body), []);
	const [ copyCompleted, setCopyCompleted ] = useState(false);
	const containerRef = useRef<HTMLDivElement>();
	const headlineRef = useRef<HTMLDivElement>();
	const bodyRef = useRef<HTMLDivElement>();
	const ctaRef = useRef<HTMLDivElement>();

	const copyPageUrlHandler = async() => {
		await navigator.clipboard.writeText(location.origin);
		window.gtag && gtag('event', 'copied_link');
		setCopyCompleted(true);
		await timeout(3000);
		setCopyCompleted(false);
	};

	useEffect(() => {
		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end:  'bottom top',
					scrub: 1,
					invalidateOnRefresh: true,
				},
			});

			if(headlineRef.current) {
				timeline.fromTo(headlineRef.current, { y: 25 }, { y: -25 }, 0);
			}

			timeline.fromTo(bodyRef.current, { y: 50 }, { y: -25 }, 0);


			if(ctaRef.current) {
				timeline.fromTo(ctaRef.current, { y: -5 }, { y: 25 }, 0);
			}

			return () => { timeline.kill(); };
		}});
	}, []);

	return (
		<section ref={containerRef} className='c1-content-block'>
			<div className='box-wide'>
				{headline && <h2 ref={headlineRef} className={`animate-y-2 c1-content-block__headline headline-2 ${alignHeadlineRight ? 'c1-content-block__headline--right-align' : ''}`}>{headline}</h2>}
				<div className={` animate-y-1 c1-content-block__content ${!headline ? 'c1-content-block__content--centered' : ''}`}>
					<div ref={bodyRef} className='c1-content-block__body body-text content-editor' dangerouslySetInnerHTML={htmlRaw(bodyHtml)}></div>
					{contactFormLink[0] && <div ref={ctaRef}><CustomLink className='c1-content-block__cta' link={contactFormLink[0]} /></div>}
					{copyLinkCaption && <div ref={ctaRef}><Button className='c1-content-block__cta' caption={copyCompleted ? copiedLinkCaption : copyLinkCaption} onClick={copyPageUrlHandler} actionCompleted={copyCompleted} /></div>}
				</div>
			</div>
		</section>
	);
};

export default C1ContentBlock;