import React, { useEffect, useRef, useState } from 'react';
import ScrollTrigger from '../../../../lib/ScrollTrigger';
import { useWindowSize } from '../../../../hooks/use-window-size';
import { IZigZagText } from '../../../../types/global.types';

const ZigZagText = ({ horizontalText, verticalText, backgroundImage, className, timeline }: IZigZagText) => {
	const { width } = useWindowSize();
	const verticalTextRef = useRef<HTMLDivElement>(null);
	const [ rightMarginToCenterVericalText, setRightMarginToCenterVericalText ] = useState(0);

	useEffect(() => {
		if(width) {
			const halfWindowWidth = width / 2;
			const rotateTextHalfWidth = verticalTextRef.current?.offsetHeight / 2;
			setRightMarginToCenterVericalText(halfWindowWidth - rotateTextHalfWidth);
		}
	}, [width]);

	useEffect(() => {
		if(!timeline) { return; }

		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			timeline.fromTo(verticalTextRef.current, { x: 200 }, { x: 0 }, 0);
		}});
	}, [timeline]);

	return (
		<section className={`zig-zag-text ${className}`}>
			<div className='zig-zag-text__inner' style={{marginRight: rightMarginToCenterVericalText}}>
				<div className='zig-zag-text__item zig-zag-text__item--horizontal'>{horizontalText}</div>
				<div className='zig-zag-text__item__vertical-text-wrapper' >
					<div ref={verticalTextRef} className='zig-zag-text__item zig-zag-text__item--vertical' style={{backgroundImage: `url(${backgroundImage.filename})`}}>{verticalText}</div>
				</div>
			</div>
		</section>
	);
};

export default ZigZagText;