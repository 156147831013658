import React from 'react';
import SbEditable from 'storyblok-react';
import C1ContentBlock from './c1-content-block/c1-content-block';
import C2MediaBanner from './c2-media-banner/c2-media-banner';
import C3ContentBlockWithImage from './c3-content-block-with-image/c3-content-block-with-image';
import C4HorizontalScrollGroup from './c4-horizontal-scroll-group/c4-horizontal-scroll-group';
import C5ExpandableGallery from './c5-expandable-gallery/c5-expandable-gallery';
import C6Table from './c6-table/c6-table';
import F1ContactForm from './f1-contact-form/f1-contact-form';

const Components = {
	'c1-content-block': C1ContentBlock,
	'c2-media-banner': C2MediaBanner,
	'c3-content-block-with-image': C3ContentBlockWithImage,
	'c4-horizontal-scroll-group': C4HorizontalScrollGroup,
	'c5-expandable-gallery': C5ExpandableGallery,
	'c6-table': C6Table,
	'f1-contact-form': F1ContactForm,
};

const DynamicComponent = ({ blok, ...rest }) => {
	if  (typeof Components[blok.component] !== 'undefined') {
		const Component = Components[blok.component];
		return (
			<SbEditable content={blok}>
				<Component {...blok} {...rest}/>
			</SbEditable>
		);
	}

	return <></>;
};

export default DynamicComponent;
