import React, { useEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from '../../lib/ScrollTrigger';
import { ITable } from '../../types/global.types';
import { richTextResolver } from '../../utils/rich-text-resolver';
import { htmlRaw } from '../../utils/utils';

const C6Table = ({ headRow, bodyRow }: ITable) => {
	const ref = useRef(null);
	const headRowCellsHtml = useMemo(() => headRow?.map(hrc => richTextResolver(hrc.content)), []);
	const bodyRowCellsHtml = useMemo(() => bodyRow?.map(brc => richTextResolver(brc.content)), []);

	useEffect(() => {
		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			gsap.fromTo(ref.current, {
				y: 25,
			}, {
				y: -25,
				scrollTrigger: {
					trigger: ref.current,
					start: 'top bottom',
					end:  'bottom top',
					scrub: 1,
					invalidateOnRefresh: true,
				},
			});
	
			return () => {
				gsap.killTweensOf(ref.current);
			};
		}});
	}, []);

	return (
		<section ref={ref} className='c6-table'>
			<div className='c6-table__row c6-table__row--head'>
				{headRowCellsHtml?.map((hrc, index) => <div className={`c6-table__cell ${headRow[index].spanThreeCells ? 'c6-table__cell--wide' : '' } content-editor`} key={index} dangerouslySetInnerHTML={htmlRaw(hrc)}></div>)}
			</div>
			<div className='c6-table__row'>
				{bodyRowCellsHtml?.map((brc, index) => <div className={`c6-table__cell ${bodyRow[index].spanThreeCells ? 'c6-table__cell--wide' : '' } content-editor`} key={index} dangerouslySetInnerHTML={htmlRaw(brc)}></div>)}
			</div>
		</section>
	);
};

export default C6Table;