import React, { useState } from 'react';


const Input = ({label, value, onChange, failedValidation, error = ''}) => {
	const [ isFocused, setIsFocused ] = useState(false);

	const focusHandler = (shouldFocus) => {
		setIsFocused(shouldFocus);
	};

	return (
		<div className={`input ${isFocused || value ? 'input--touched' : ''} ${failedValidation ? 'input--errored' : ''}`}>
			<label className='input__label'>{label}</label>
			<input className="input__field" type='text' required={true} onFocus={() => focusHandler(true)} onBlur={() => focusHandler(false)} onChange={onChange} />
			{error && <span className="input__error">{error}</span>}
		</div>
	);
};

export default Input;