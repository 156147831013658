import React, { useEffect, useRef } from 'react';
import ScrollTrigger from '../../../../lib/ScrollTrigger';
import { ITwoImageBlock } from '../../../../types/global.types';
import Picture from '../../../picture/picture';

const TwoImageBlock = ({ firstImage, secondImage, firstImageShouldBeLarge, className, timeline }: ITwoImageBlock) => {
	const ref = useRef(null);
	const firstImageRef = useRef(null);
	const secondImageRef = useRef(null);

	const landscapeQueries = [
		{ alias: 'desktop', height: 210, width: 309, media: '(min-width: 768px)' },
		{ alias: 'tablet', height: 203, width: 308, media: '(max-width: 767px)' },
	];
	const portraitQueries = [
		{ alias: 'desktop', height: 597, width: 398, media: '(min-width: 768px)' },
		{ alias: 'tablet', height: 351, width: 308, media: '(max-width: 767px)' },
	];

	useEffect(() => {
		if(!timeline) { return; }
		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			const down = !firstImageShouldBeLarge ? firstImageRef.current : secondImageRef.current;
			const up = firstImageShouldBeLarge ? firstImageRef.current : secondImageRef.current;
			timeline.to(down, { y: 50 }, 0);
			timeline.to(up, { y: -25 }, 0);
		}});
	}, [timeline]);
	
	return (
		<section ref={ref} className={`two-image-block ${firstImageShouldBeLarge ? 'two-image-block--first-large' : ''} ${className}`}>
			<div ref={firstImageRef} className='two-image-block__first-image'>
				<Picture image={firstImage} queries={firstImageShouldBeLarge ? portraitQueries : landscapeQueries} />
			</div>
			<div ref={secondImageRef} className='two-image-block__second-image'>
				<Picture image={secondImage} queries={firstImageShouldBeLarge ? landscapeQueries : portraitQueries} />
			</div>
		</section>
	);
};

export default TwoImageBlock;