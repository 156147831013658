import gsap from 'gsap';
import React, { useEffect, useRef } from 'react';
import ScrollTrigger from '../../lib/ScrollTrigger';
import { IExpandableGallery } from '../../types/global.types';
import Picture from '../../components/picture/picture';
import { useWindowSize } from '../../hooks/use-window-size';

const BORDER_WIDTH_OFFSET = 3;

const C5ExpandableGallery = ({ gallery }: IExpandableGallery) => {
	const { width: windowWidth, isMobile } = useWindowSize();
	const containerRef = useRef<HTMLDivElement>(null);
	const imagesContainerRef = useRef<HTMLDivElement[]>([]);
	const innerContainerRef = useRef<HTMLDivElement>(null);
	const borderWidthOffsetInPercent = BORDER_WIDTH_OFFSET * 100 / windowWidth;
	const firstImageConfig = {
		widthPercentage: isMobile ? 67 : 22,
		heightPercentage: isMobile ? 38 + borderWidthOffsetInPercent : 50,
	};
	const secondImageConfig = {
		widthPercentage: isMobile ? 100 : 100 - firstImageConfig.widthPercentage + borderWidthOffsetInPercent,
		heightPercentage: isMobile ? 28 : 44,
	};
	const thirdImageConfig = {
		widthPercentage: isMobile ? 100 - firstImageConfig.widthPercentage + borderWidthOffsetInPercent : firstImageConfig.widthPercentage,
		heightPercentage: isMobile ? firstImageConfig.heightPercentage : 35,
	};
	const fourthImageConfig = {
		widthPercentage: isMobile ? 43 : 15,
		heightPercentage: isMobile ? 27 + (borderWidthOffsetInPercent * 2) : 50,
	};
	const fifthImageConfig = {
		widthPercentage: isMobile ? 100 - fourthImageConfig.widthPercentage + (borderWidthOffsetInPercent * 2) : 100 - thirdImageConfig.widthPercentage - fourthImageConfig.widthPercentage + (borderWidthOffsetInPercent * 2),
		heightPercentage: isMobile ? 100 - secondImageConfig.heightPercentage - thirdImageConfig.heightPercentage + borderWidthOffsetInPercent : 100 - secondImageConfig.heightPercentage + borderWidthOffsetInPercent,
	};

	const queries = [
		[
			{ alias: 'desktop', height: 360, width: 281, media: '(min-width: 1024px)'},
			{ alias: 'desktopSmall', height: 288, width: 226, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 394, width: 515, media: '(min-width: 641px)' },
			{ alias: 'mobileLarge', height: 327, width: 429, media: '(min-width: 481px)' },
			{ alias: 'mobile', height: 328, width: 322, media: '(max-width: 480px)' },
		],
		[
			{ alias: 'desktop', height: 317, width: 999, media: '(min-width: 1024px)'},
			{ alias: 'desktopSmall', height: 254, width: 801, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 287, width: 768, media: '(min-width: 641px)' },
			{ alias: 'mobileLarge', height: 238, width: 640, media: '(min-width: 481px)' },
			{ alias: 'mobile', height: 238, width: 480, media: '(max-width: 480px)' },
		],
		[
			{ alias: 'desktop', height: 252, width: 281, media: '(min-width: 1024px)'},
			{ alias: 'desktopSmall', height: 202, width: 226, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 394, width: 257, media: '(min-width: 641px)' },
			{ alias: 'mobileLarge', height: 296, width: 215, media: '(min-width: 481px)' },
			{ alias: 'mobile', height: 297, width: 162, media: '(max-width: 480px)' },
		],
		[
			{ alias: 'desktop', height: 360, width: 192, media: '(min-width: 1024px)'},
			{ alias: 'desktopSmall', height: 288, width: 154, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 285, width: 331, media: '(min-width: 641px)' },
			{ alias: 'mobileLarge', height: 215, width: 275, media: '(min-width: 481px)' },
			{ alias: 'mobile', height: 207, width: 217, media: '(max-width: 480px)' },
		],
		[
			{ alias: 'desktop', height: 404, width: 809, media: '(min-width: 1024px)'},
			{ alias: 'desktopSmall', height: 324, width: 651, media: '(min-width: 769px)' },
			{ alias: 'tablet', height: 349, width: 444, media: '(min-width: 641px)' },
			{ alias: 'mobileLarge', height: 262, width: 371, media: '(min-width: 481px)' },
			{ alias: 'mobile', height: 262, width: 280, media: '(max-width: 480px)' },
		],
	];

	const convertPercentagesToPixels = (percentages, forXCoordinate = false) => {
		const containerSize = forXCoordinate ? innerContainerRef.current.offsetWidth : innerContainerRef.current.offsetHeight; 

		return Math.floor(percentages * containerSize / 100);
	};

	const getImageCoords = (el: HTMLDivElement, index) => {
		let x, y, width, height ;

		switch(index) {
		case 0:
		default:
			x = 0;
			y = isMobile ? convertPercentagesToPixels(secondImageConfig.heightPercentage) - BORDER_WIDTH_OFFSET : 0;
			width = firstImageConfig.widthPercentage;
			height = firstImageConfig.heightPercentage;
			break;
		case 1:
			x = isMobile ? 0 : convertPercentagesToPixels(firstImageConfig.widthPercentage, true) - BORDER_WIDTH_OFFSET;
			y = 0;
			width = secondImageConfig.widthPercentage;
			height = secondImageConfig.heightPercentage;
			break;
		case 2:
			x = isMobile ? convertPercentagesToPixels(firstImageConfig.widthPercentage, true) - BORDER_WIDTH_OFFSET : 0;
			y = convertPercentagesToPixels(isMobile ? secondImageConfig.heightPercentage : firstImageConfig.heightPercentage) - BORDER_WIDTH_OFFSET;
			width = thirdImageConfig.widthPercentage;
			height = thirdImageConfig.heightPercentage;
			break;
		case 3:
			x = isMobile ? 0 : convertPercentagesToPixels(firstImageConfig.widthPercentage, true) - BORDER_WIDTH_OFFSET;
			y = convertPercentagesToPixels(secondImageConfig.heightPercentage + (isMobile ? firstImageConfig.heightPercentage : 0)) - (BORDER_WIDTH_OFFSET * isMobile ? 6 : 1);
			width = fourthImageConfig.widthPercentage;
			height = fourthImageConfig.heightPercentage;
			break;
		case 4:
			x = convertPercentagesToPixels(fourthImageConfig.widthPercentage + (isMobile ? 0 : thirdImageConfig.widthPercentage), true) - BORDER_WIDTH_OFFSET * 2;
			y = convertPercentagesToPixels(secondImageConfig.heightPercentage + (isMobile ? firstImageConfig.heightPercentage : 0)) - (BORDER_WIDTH_OFFSET * isMobile ? 6 : 1);
			width = fifthImageConfig.widthPercentage;
			height = fifthImageConfig.heightPercentage;
			break;
		}

		return { x, y, width, height };
	};
		
	useEffect(() => {
		if(windowWidth) {
			ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
				const timeline = gsap.timeline({
					scrollTrigger: {
						trigger: containerRef.current,
						start: 'center 55%',
						end:  'center 50%',
						scrub: 1,
						invalidateOnRefresh: true,
					},
				});
				
				imagesContainerRef.current.forEach((image, index) => {
	
					if(image) {
						const { x, y, width, height } = getImageCoords(image, index);
						gsap.set(image, { width: `${width}%`, height: `${height}%` });
	
						timeline.to(image, {
							scale: 1,
							y: y,
							x: x,
							xPercent: 0,
							yPercent: 0,
							top: 0,
							left: 0,
							ease: 'none',
						}, 0);
					}
				});
		
				return () => {
					timeline.kill();
				};
			}, '(prefers-reduced-motion: reduce)': () => {
				
				imagesContainerRef.current.forEach((image, index) => {
	
					if(image) {
						const { x, y, width, height } = getImageCoords(image, index);
						gsap.set(image, { 
							width: `${width}%`, 
							height: `${height}%`,
							scale: 1,
							y: y,
							x: x,
							xPercent: 0,
							yPercent: 0,
							top: 0,
							left: 0,
							ease: 'none',
						});
					}
				});
			}});
		}
	}, [windowWidth]);

	return (
		<section ref={containerRef} className='c5-expandable-gallery'>
			<div ref={innerContainerRef} className='c5-expandable-gallery__inner'>
				{gallery.map((image, index) =>
					<div ref={(el) => imagesContainerRef.current[index] = el} key={image.id} className='c5-expandable-gallery__image-wrapper'>
						<Picture image={image} queries={queries[index]} />
					</div>,
				)}
			</div>
		</section>
	);
};

export default C5ExpandableGallery;