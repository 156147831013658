import React, { SyntheticEvent, useEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from '../../lib/ScrollTrigger';
import { IContactForm } from '../../types/global.types';
import { useState } from 'react';
import Button from '../button/button';
import { richTextResolver } from '../../utils/rich-text-resolver';
import Input from '../input/input';

const PHONE_NUMBER_REGEX = /^[0-9]{8}$/;

const F1ContactForm = ({ anchor, headline, description, nameInputLabel, phoneInputLabel, phoneInputError, submitButtonCaption, submitButtonFeedbackCaption, mailFrom, mailTo, mailSubject, mailBody }: IContactForm) => {
	const bodyHtml = useMemo(() => richTextResolver(mailBody), []);
	const [ name, setName ] = useState('');
	const [ phoneNumber, setPhoneNumber ] = useState('');
	const [ submitted, setSubmitted ] = useState(false);
	const [ rejectNameSubmit, setRejectSubmit ] = useState(false);
	const [ rejectPhoneNumberSubmit, setRejectPhoneNumberSubmit ] = useState(false);
	const containerRef = useRef<HTMLDivElement>();
	const headlineRef = useRef<HTMLDivElement>();
	const bodyRef = useRef<HTMLDivElement>();
	
	const constructHTML = () => {
		const nameRegex = /#\/#(name)#\\#+/g;
		const phoneRegex = /#\/#(number)#\\#+/g;
		const bodyWithReplacedValues = bodyHtml.replace(nameRegex, name).replace(phoneRegex, phoneNumber);

		const html = 
			`<html lang="da">
				<head>
					<meta charset="UTF-8"><meta name="viewport" content="width=device-width, initial-scale=1.0">
					<meta http-equiv="X-UA-Compatible" content="ie=edge">
					<title>Projekt Vega</title>
				</head>
				<body>
				${bodyWithReplacedValues}
				</body>
			</html>`;

		return html;
	};

	const validate = () => {
		const nameIsValid = !!name;
		const phoneIsValid = phoneNumber && PHONE_NUMBER_REGEX.test(phoneNumber);

		if(!nameIsValid) {
			setRejectSubmit(true);
		}

		if(!phoneIsValid) {
			setRejectPhoneNumberSubmit(true);
		}

		return nameIsValid && phoneIsValid;
	};

	const formSubmitHandler = async (e: SyntheticEvent) => {
		e.preventDefault();

		if(!validate()) { 
			return;
		}

		const html = constructHTML();

		const body = {
			formSubmissionEmail: {
				from: mailFrom,
				to: [mailTo],
				subject: mailSubject,
				html: html,
			},
		};

		try {
			const response = await fetch(process.env.GATSBY_EMAIL_SERVICE_API_URL, {
				method:'POST',
				body: JSON.stringify(body),
			});
			
			if(!response.ok) {
				window.gtag && gtag('event', 'contact_form_failed');
			} else {
				window.gtag && gtag('event', 'contact_form_completed');
				setSubmitted(true);
			}
		} catch(err) {
			console.error(err);
		}
	};

	const nameInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setRejectSubmit(false);
		setName(e.currentTarget.value);
	};

	const phoneInputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
		setRejectPhoneNumberSubmit(false);
		setPhoneNumber(e.currentTarget.value);
	};


	useEffect(() => {
		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end:  'bottom top',
					scrub: 1,
					invalidateOnRefresh: true,
				},
			});
	
			timeline.fromTo(headlineRef.current, { y: 50 }, { y: -25 }, 0);
			timeline.fromTo(bodyRef.current, { y: 100 }, { y: -25 }, 0);

			return () => {
				timeline.kill();
			};
		}});
	}, []);

	return (
		<section ref={containerRef} id={anchor} className='f1-contact-form'>
			<div className='box-wide'>
				<h2 ref={headlineRef} className='f1-contact-form__headline headline-2'>{headline}</h2>
				<div ref={bodyRef} className='f1-contact-form__content'>
					<p className='f1-contact-form__description body-text'>{description}</p>
					<form className='f1-contact-form__form'>
						<Input label={nameInputLabel} value={name} onChange={nameInputChangeHandler} failedValidation={rejectNameSubmit} />
						<Input label={phoneInputLabel} value={phoneNumber} onChange={phoneInputChangeHandler}  failedValidation={rejectPhoneNumberSubmit} error={phoneInputError} />
						<Button className='f1-contact-form__form__submit-button' caption={submitted ? submitButtonFeedbackCaption : submitButtonCaption} onClick={formSubmitHandler} actionCompleted={submitted} />
					</form>
				</div>
			</div>
		</section>
	);
};

export default F1ContactForm;