import React, { useMemo } from 'react';
import { ITextBlock } from '../../../../types/global.types';
import { richTextResolver } from '../../../../utils/rich-text-resolver';
import { htmlRaw } from '../../../../utils/utils';

const TextBlock = ({ headline, bodyFirstColumn, bodySecondColumn, offsetHeadline, className }: ITextBlock) => {
	const bodyFirstColumnHtml = useMemo(() => richTextResolver(bodyFirstColumn), []);
	const bodySecondColumnHtml = bodySecondColumn && useMemo(() => richTextResolver(bodySecondColumn), []);

	return (
		<section className={`text-block ${className}`}>
			<div className='text-block__inner'>
				{headline && <h2 className='text-block__headline headline-2'>{headline}</h2>}
				<div className={`text-block__body-wrapper ${offsetHeadline ? 'text-block__body-wrapper--offset' : ''}`}>
					<div className='text-block__body text-block__body--first-column body-text content-editor' dangerouslySetInnerHTML={htmlRaw(bodyFirstColumnHtml)}></div>
					{bodySecondColumnHtml && <div className={`text-block__body text-block__body--second-column body-text content-editor ${offsetHeadline ? 'text-block__body--offset' : ''}`} dangerouslySetInnerHTML={htmlRaw(bodySecondColumnHtml)}></div>}
				</div>
			</div>
		</section>
	);
};

export default TextBlock;