import React from 'react';
import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import ArrowIcon from '../../svg/arrow-icon';
import { ILink } from '../../types/global.types';

const CustomLink = ({ link, className='', external=false }: { link: ILink, className?: string, external?:boolean }) => {
	const classes = `button button--link ${className}`;

	if(external) {
		return (
			<a href={link.link.url} target='_blank' rel='noreferrer' className={classes}>
				<span className='button__caption'>{link.caption}</span>
				<ArrowIcon />
			</a>
		);
	}

	if(link.link.anchor) {
		return (
			<AnchorLink to={`/#${link.link.anchor}`} className={classes}>
				<span className='button__caption'>{link.caption}</span>
				<ArrowIcon />
			</AnchorLink>
		);
	}

	return(
		<Link to={`/${link.link.cached_url}`} className={classes}>
			<span className='button__caption'>{link.caption}</span>
			<ArrowIcon />
		</Link>
	);
};

export default CustomLink;