import React, { useEffect, useMemo, useRef } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from '../../lib/ScrollTrigger';
import { IContentBlockWithImage } from '../../types/global.types';
import { richTextResolver } from '../../utils/rich-text-resolver';
import { htmlRaw } from '../../utils/utils';
import CustomLink from '../custom-link/custom-link';
import Picture from '../picture/picture';

const C3ContentBlockWithImage = ({ headline, body, image, cta }: IContentBlockWithImage) => {
	const containerRef = useRef<HTMLDivElement>();
	const imageContainerRef = useRef<HTMLDivElement>();
	const headlineRef = useRef<HTMLDivElement>();
	const bodyRef = useRef<HTMLDivElement>();
	const ctaRef = useRef<HTMLDivElement>();
	const bodyHtml = useMemo(() => richTextResolver(body), []);
	const queries = [
		{ alias: 'desktop', height: 565, width: 476, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: 850, width: 663, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: 638, width: 498, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: 606, width: 473, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: 440, width: 344, media: '(max-width: 480px)' },
	];
	

	useEffect(() => {
		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			const timeline = gsap.timeline({
				scrollTrigger: {
					trigger: containerRef.current,
					start: 'top bottom',
					end:  'bottom top',
					scrub: 1,
					invalidateOnRefresh: true,
				},
			});
	
			timeline.fromTo(imageContainerRef.current, { y: -15 }, { y: 5 }, 0);

			timeline.fromTo(headlineRef.current, { y: 50 }, { y: -25 }, 0);
	
			timeline.fromTo(bodyRef.current, { y: 50 }, { y: -25 }, 0);
	
	
			if(ctaRef.current) {
				timeline.fromTo(ctaRef.current, { y: 25 }, { y: -25 }, 0);
			}

			return () => { timeline.kill(); };
		}});
	}, []);

	return (
		<section ref={containerRef} className='animate-container c3-content-block-with-image'>
			<div className='box-wide c3-content-block-with-image__inner-wrapper'>
				<div ref={imageContainerRef} className='animate-y-2-reversed c3-content-block-with-image__picture-wrapper' >
					<Picture image={image} queries={queries} />
				</div>
				<div className='c3-content-block-with-image__content'>
					<h2 ref={headlineRef} className='animate-y-1 c3-content-block-with-image__content__headline headline-2'>{headline}</h2>
					<div ref={bodyRef} className='animate-y-2 c3-content-block-with-image__content__body content-editor' dangerouslySetInnerHTML={htmlRaw(bodyHtml)}></div>
					{cta[0] && 
					<div ref={ctaRef} className='c3-content-block-with-image__content__cta'>
						<CustomLink link={cta[0]} external={true} />
					</div>
					}
				</div>
			</div>
		</section>
	);
};

export default C3ContentBlockWithImage;