import React, { useRef, useEffect, useState } from 'react';
import { gsap } from 'gsap';
import ScrollTrigger from '../../lib/ScrollTrigger';
import { useIntersectionObserver } from '../../hooks/use-intersection-observer';
import Picture from '../picture/picture';
import { IMediaBanner } from '../../types/global.types';

const C2MediaBanner = ({ video, image, videoPlayCaption }: IMediaBanner) => {
	const [ isIOS, setIsIOS ] = useState(false);
	const [ playInitiated, setPlayInitiated ] = useState(false);
	const [ loaded, setLoaded ] = useState(false);
	const videoRef = useRef<HTMLVideoElement>(null);
	const ref = useRef(null);
	const getHeight = (width) => Math.ceil(width * .56);
	const queries = [
		{ alias: 'desktop', height: getHeight(1366), width: 1366, media: '(min-width: 1024px)' },
		{ alias: 'desktopSmall', height: getHeight(1024), width: 1024, media: '(min-width: 769px)' },
		{ alias: 'tablet', height: getHeight(768), width: 768, media: '(min-width: 641px)' },
		{ alias: 'mobileLarge', height: getHeight(640), width: 640, media: '(min-width: 481px)' },
		{ alias: 'mobile', height: getHeight(480), width: 480, media: '(max-width: 480px)' },
	];

	const isIntersecting = useIntersectionObserver(ref, {
		root: null,
		threshold: 0,
		rootMargin: '200px',
	}, false);

	const videoPlayHandler = () => {
		if(playInitiated) { return; }

		window.gtag && gtag('event', 'video_play_start', {
			'event_label': 'Video',
			'event_category': 'video_play',
		});

		videoRef.current.play();

		setPlayInitiated(true);
	};

	const videoEndedHandler = () => {
		window.gtag && gtag('event', 'video_play_end', {
			'event_label': 'Video',
			'event_category': 'video_play',
		});
	};

	const handleLoadedDataForIOS = () => {
		videoRef.current.pause();
		videoRef.current.muted = false;
	};

	useEffect(() => {
		if(videoRef.current && !loaded) {
			videoRef.current.addEventListener('playing', () => {
				if(!videoRef.current.controls) {
					videoRef.current.setAttribute('controls','controls');
				}
			});
			setLoaded(true);
		}

		if(!isIntersecting && loaded) {
			videoRef.current.pause();
		}
	}, [videoRef.current, isIntersecting]);

	useEffect(() => {
		ScrollTrigger.matchMedia({'(prefers-reduced-motion: no-preference)': () => {
			gsap.fromTo(ref.current, {
				y: 25,
			}, {
				y: -25,
				scrollTrigger: {
					trigger: ref.current,
					start: 'top bottom',
					end:  'bottom top',
					scrub: 1,
					invalidateOnRefresh: true,
				},
			});
		}});

		return () => gsap.killTweensOf(ref.current);
	}, []);

	useEffect(() => {
		if([
			'iPad Simulator',
			'iPhone Simulator',
			'iPod Simulator',
			'iPad',
			'iPhone',
			'iPod',
		].includes(navigator.platform)
		// iPad on iOS 13 detection
		|| (navigator.userAgent.includes('Mac') && 'ontouchend' in document)) {
			setIsIOS(true);
		}
	}, []);

	return (
		<section ref={ref} className='c2-media-banner'>
			{isIntersecting || loaded ? 
				video?.id ?
					<>
						<video ref={videoRef} preload="metadata" src={video.filename} playsInline className='c2-media-banner__video' poster={image.filename} onClick={videoPlayHandler} onLoadedData={isIOS ? handleLoadedDataForIOS : null} onEnded={videoEndedHandler}>
						</video>
						{!playInitiated && <div className='c2-media-banner__video__play-caption'>{videoPlayCaption}</div>}
					</>
					: <Picture queries={queries} image={image} />
				: null}
		</section>
	);
};

export default C2MediaBanner;
