import React from 'react';
import Layout from '../components/layout/layout';
import Meta from '../components/meta';
import DynamicComponent from '../components/dynamic-component';
import { useStoryblok } from '../lib/storyblok';
import { IFrontpage } from '../types/global.types';

const Frontpage = ({ pageContext, location }) => {
	let story = pageContext.story;
	story = useStoryblok(story, location);
	const { 
		headerLink,
		a2_footer,
		b1_hero,
		body,
		title,
		description,
	}: IFrontpage = story.content;

	const components = body.map(blok => (
		<DynamicComponent key={blok._uid} blok={blok} />
	));

	return (
		<Layout headerData={headerLink[0]} footerData={a2_footer[0]} heroData={b1_hero[0]} className='frontpage'>
			<Meta title={title} description={description} />
			<div className='main__components-wrapper'>
				{components}
			</div>
		</Layout>
	);
};

export default Frontpage;
