import React from 'react';

const ArrowIcon = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20.117 15.757" className='arrow-icon'>
		<g>
			<g fill="none" stroke="#000" strokeWidth="2" >
				<path d="M0 7.889h19.021" />
				<path d="m12.672.635 6.134 7.47-6.134 6.993" />
			</g>
		</g>
	</svg>
);

export default ArrowIcon;