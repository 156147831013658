import React from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import ArrowIcon from '../../svg/arrow-icon';

const Button = ({ caption, className, onClick, actionCompleted}) => {
	const elRef = useRef<HTMLButtonElement>(null);
	const [ initialWidth, setInitialWidth ] = useState(0); //in case caption changes we want to keep the initial width

	useEffect(() => {
		if(elRef.current) {
			setInitialWidth(elRef.current.offsetWidth);
		}
	}, [elRef.current]);

	return(
		<button ref={elRef} className={`button ${className} ${actionCompleted ? 'button--action-completed' : ''}`} onClick={onClick} style={{minWidth: initialWidth ? initialWidth : 'auto'}}>
			<span className='button__caption'>{caption}</span>
			<ArrowIcon />
		</button>
	);
};

export default Button;