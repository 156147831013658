import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react';
import { IHorizontalScrollGroup } from '../../types/global.types';
import Image from './components/image/image';
import TextBlock from './components/text-block/text-block';
import TwoImageBlock from './components/two-image-block/two-image-block';
import ZigZagText from './components/zig-zag-text/zig-zag-text';

const HorizontalScrollGroupComponents = {
	'image': Image,
	'textBlock': TextBlock,
	'twoImageBlock': TwoImageBlock,
	'zigZagText': ZigZagText,
};


const C4HorizontalScrollGroup = ({ sections }: IHorizontalScrollGroup) => {
	const sectionsComponents = sections.map(sec => HorizontalScrollGroupComponents[sec.component]);
	const containerRef = useRef<HTMLDivElement>(null);
	const scrollContainerRef = useRef<HTMLDivElement>(null);
	const innerContainerRef = useRef<HTMLDivElement>(null);
	const [ withExtraBottomPadding, setWithExtraBottomPadding ] = useState(false);
	const [ timeline, setTimeline ] = useState(null);

	const getRightSidePadding = () => sections[sections.length - 1].component === 'zigZagText' ? 0 : innerContainerRef.current.offsetLeft;

	useEffect(() => {
		if(!timeline) { return; }

		timeline.to(scrollContainerRef.current, {
			x: () => -(scrollContainerRef.current.scrollWidth - window.innerWidth + getRightSidePadding()),
			ease: 'none',
		}, 0);
	
		return () => {
			timeline.kill();
		};
	}, [timeline]);

	useEffect(() => {
		const containsComponentWithAbsoluteOffset = sections.find(section => section.component === 'zigZagText');
		setWithExtraBottomPadding(!!containsComponentWithAbsoluteOffset);

		setTimeline(gsap.timeline({
			scrollTrigger: {
				trigger: containerRef.current,
				start: 'top top',
				end: () => `+=${scrollContainerRef.current.scrollWidth - window.innerWidth + getRightSidePadding()}`,
				scrub: 1,
				pin: true,
				invalidateOnRefresh: true,
				anticipatePin: 1,
			},
		}));
	}, []);


	return (
		<section ref={containerRef} className={`c4-horizontal-scroll-group ${withExtraBottomPadding ? 'c4-horizontal-scroll-group--extra-bottom-padding' : ''}`}>
			<div ref={scrollContainerRef} className='c4-horizontal-scroll-group__scroll-container'>
				<div ref={innerContainerRef} className='box-wide c4-horizontal-scroll-group__inner-wrapper'>
					{sectionsComponents.map((Component, index) => <Component key={sections[index]._uid} {...sections[index]} className='c4-horizontal-scroll-group__component' index={index} timeline={timeline} />)}
				</div>
			</div>
		</section>
	);
};

export default C4HorizontalScrollGroup;